import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import FeaturedBlock from "../components/book-featured-block";
import ContentBlock from "../components/book-content-block";
import ChaptersCarousel from "../components/book-chapters-carousel";
import WaysToRead from "../components/book-ways-to-read";
import Quotes from "../components/book-quotes";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query BookPageQuery {
    book: sanityBook {
      bookFeaturedBlock {
        _rawHeading
        topSubheading
        bottomSubheading
        description
        image {
          ...SanityImage
          alt
        }
      }
      bookContentBlock {
        description
        image {
          ...SanityImage
          alt
        }
        link {
          text
          linkUrl
          externalContent
        }
      }
      _rawChaptersHeading
      bookWaysToRead {
        heading
        waysToRead {
          _key
          bookFile {
            asset {
              _id
              url
            }
          }
          linkUrl
          title
          cover {
            ...SanityImage
            alt
          }
        }
      }
      bookQuotes {
        heading
        quotes {
          _key
          description
          logo {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }
  }
`;

const BookPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const book = (data || {}).book;

  return (
    <Layout>
      <Seo
        title="The Story of lululemon"
        description={book.bookFeaturedBlock.description}
      />

      {book.bookFeaturedBlock && (
        <FeaturedBlock {...book.bookFeaturedBlock} />
      )}

      {book.bookContentBlock && (
        <ContentBlock {...book.bookContentBlock} />
      )}

      {/*<ChaptersCarousel title={book._rawChaptersHeading} />*/}

      {book.bookWaysToRead && (
        <WaysToRead {...book.bookWaysToRead} />
      )}

      {book.bookQuotes && (
        <Quotes {...book.bookQuotes} />
      )}
    </Layout>
  );
};

export default BookPage;
