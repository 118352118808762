import React from "react";
import BookFormats from "./book-formats";
import Container from "./container";

import * as styles from "../styles/components/book-ways-to-read.module.css";

const WaysToRead = (props) => {

  const {
    heading,
    waysToRead
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.containerContent}>
          {heading && (
            <h2 className={styles.heading} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
              {heading}
            </h2>
          )}
          {waysToRead && <BookFormats items={waysToRead} />}
        </div>
      </Container>
    </div>
  );
}

export default WaysToRead;
