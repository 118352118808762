import React from "react";
import QuoteList from "./quote-list";
import Container from "./container";

import * as styles from "../styles/components/book-quotes.module.css";

const Quotes = (props) => {

  const {
    heading,
    quotes
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.containerHeading} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          {heading && (
            <h2 className={styles.heading}>
              {heading}
            </h2>
          )}
        </div>
        <div className={styles.quotesWrap} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          <div className={styles.containerContent}>
            {quotes && <QuoteList items={quotes} />}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Quotes;
